<script lang="ts" setup>
import type { LightCashResponse } from "@/types";

const props = defineProps<{
	activePackage: LightCashResponse["packages"][number];
	isShowTicket?: boolean;
	badgeText: string;
	bgColor: string;
}>();

const { t } = useT();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { data: appInitData } = useAppInitData();

const isFreeSpin = computed(() => props?.activePackage?.promoOfferPreset?.freeSpin);
const isFreeSpinEntries = computed(() => isFreeSpin.value && props.activePackage?.promoOfferPreset?.entries);
const isBanner = computed(() => props.activePackage?.promoOfferPreset?.imageBannerMobile);
const hasTitle = computed(() => !!props.activePackage?.promoOfferPreset?.title);
const isActiveSeason = computed(() => !appInitData.value?.season?.isAvailable);
const isActiveDepositStreak = computed(() => appInitData.value?.depositStreak?.isActive);

const bannerImg = computed(() => {
	if (isBanner.value) {
		return `url(${baseImageUrl}${isBanner.value}), ${props.bgColor}`;
	}

	return "var(--compton)";
});
</script>
<template>
	<div class="wrap">
		<div
			:class="[
				'banner',
				{
					isTicket: isShowTicket && !isBanner,
					isNoTitle: isBanner && !hasTitle,
					isNoBanner: !(isBanner && !isFreeSpin)
				}
			]"
		>
			<ABadge
				v-if="activePackage.promoOfferPreset"
				variant="skew"
				background="var(--cebu)"
				:class="{
					'most-popular': activePackage.promoOfferPreset.mostPopular,
					'rank-league': activePackage.promoOfferPreset.rankLeague,
					'free-spin': isFreeSpin
				}"
				autosize
			>
				<AText variant="topeka" :modifiers="['bold', 'uppercase']"> {{ badgeText }}</AText>
			</ABadge>
			<AText v-if="!isBanner" class="title" :modifiers="['normal']" :class="{ 'no-top-offset': isFreeSpinEntries }">
				{{ t("Buy coins instantly") }}
			</AText>
			<div :class="['order', { isBanner }]">
				<AText
					v-if="isBanner && activePackage?.promoOfferPreset?.title"
					class="order-title text-cannes"
					variant="toledo"
					:modifiers="['bold']"
				>
					{{ activePackage.promoOfferPreset.title }}
				</AText>
				<AText class="order-value" variant="tanta" :modifiers="['uppercase', 'bold']" as="div">
					<NuxtImg src="/nuxt-img/cards/coins@2x.webp" alt="coins" width="24" height="24" />
					<AAnimationNumberCounter
						class="value coins"
						:number="activePackage.promoOfferPreset?.coins || activePackage.preset.coins"
					/>
				</AText>
				<template v-if="isFreeSpin">
					<AText class="order-value" variant="tanta" :modifiers="['uppercase', 'bold']" as="div">
						<span class="free-text text-uppercase">{{ t("and") }}</span>
						<i18n-t keypath="{icon} {amount} FP" tag="span" class="free-content">
							<template #icon>
								<NuxtImg src="/nuxt-img/cards/fp.png" format="webp" alt="free play" width="24" height="24" />
							</template>
							<template #amount>
								<AAnimationNumberCounter class="value coins" :number="activePackage.promoOfferPreset?.freeSpinAmount" />
							</template>
						</i18n-t>
					</AText>
					<AText
						v-if="isFreeSpinEntries"
						class="second-line"
						variant="topeka"
						:modifiers="['uppercase', 'extrabold']"
						as="div"
					>
						<i18n-t keypath="+ {amount} entries for free">
							<template #amount>
								<AAnimationNumberCounter class="value entries" :number="activePackage.promoOfferPreset?.entries" />
							</template>
						</i18n-t>
					</AText>
				</template>
				<AText v-else class="order-value" variant="tanta" :modifiers="['uppercase', 'bold']" as="div">
					<i18n-t keypath="and{delimiter}free" tag="span" class="free-text">
						<template #delimiter><br /></template>
					</i18n-t>
					<NuxtImg src="/nuxt-img/cards/entries.png" format="webp" alt="entries" width="24" height="24" />
					<AAnimationNumberCounter
						class="value entries"
						:number="activePackage.promoOfferPreset?.entries || activePackage.preset.entries"
					/>
				</AText>
			</div>

			<div v-if="!isBanner" class="pay-methods-icons">
				<NuxtIcon name="24/mc" filled />
				<NuxtIcon name="24/visa" filled />
				<NuxtIcon name="24/apple-pay" filled />
				<NuxtIcon name="24/trustly" filled />
			</div>
		</div>
		<AText
			v-if="isShowTicket"
			as="div"
			:class="['ticket', 'text-cannes', { bottom: isShowTicket && !isBanner }]"
			variant="topeka"
			:modifiers="['bold']"
		>
			<MTicket :is-deposit-streak="isActiveDepositStreak" :is-season="isActiveSeason" />
		</AText>
	</div>
</template>
<style lang="scss" scoped>
.wrap {
	position: relative;

	.ticket {
		display: none;

		@include media-breakpoint-down(md) {
			position: absolute;
			display: flex;
			align-items: center;
			gap: gutter(0.5);
			bottom: 4px;
			left: 16px;
			line-height: 16px;

			&.bottom {
				bottom: 0;
				left: 0;
				color: var(--chinatown);
			}
		}
	}
}

.banner {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	border-radius: 8px;
	position: relative;
	overflow: hidden;
	color: var(--canberra);
	font-weight: 800;

	@include media-breakpoint-down(md) {
		background: v-bind(bannerImg);
		background-repeat: no-repeat;
		background-position: right;
		background-size: contain;
		width: 304px;
		height: 140px;
		padding: 20px 12px;

		&.isTicket {
			margin-bottom: gutter(4.5);
		}

		&.isNoTitle {
			padding-top: 20px;
		}

		&.isNoBanner {
			height: 150px;
		}
	}

	.title {
		display: none;

		@include media-breakpoint-down(md) {
			display: block;
			margin: 12px 0;
			color: var(--changshu);

			&.no-top-offset {
				margin-top: 0;
			}
		}
	}

	.badge {
		box-shadow: none;
		position: absolute;
		top: 0;
		right: -3px;
		display: none;

		@include media-breakpoint-down(md) {
			display: inline-flex;
			flex-direction: row;
		}

		&::before {
			background-color: inherit;
		}

		&.most-popular {
			background: var(--cabo);
		}

		&.rank-league {
			background: var(--caceres);
		}

		&.free-spin {
			background: var(--chusovoy);
			color: var(--chomutov);
		}
	}

	.order {
		display: flex;
		gap: gutter(0.5);
		color: var(--chinatown);
		align-items: center;
		flex-wrap: wrap;

		&-value {
			display: flex;
			align-items: center;
			gap: gutter(0.5);
		}

		.free-text {
			font-size: 10px;
			line-height: 1;
			font-weight: 800;
		}

		.free-content {
			display: flex;
			align-items: center;
			gap: 3px;
		}

		&-title {
			display: none;
		}

		.second-line {
			flex-grow: 1;
			@include media-breakpoint-down(md) {
				margin-bottom: 8px;
			}
		}

		&.isBanner {
			@include media-breakpoint-down(md) {
				flex-direction: column;
				align-items: flex-start;

				.order-title {
					display: block;
				}

				.free-text {
					color: var(--cannes);
				}

				.coins {
					color: var(--chittagong);
				}

				.entries {
					color: var(--cixi);
				}
			}
		}
	}

	.price {
		flex-grow: 2;
		text-align: right;
		color: var(--chinatown);

		@include media-breakpoint-down(md) {
			font-size: 28px;
		}

		&:deep(sup) {
			top: -10px;
		}
	}

	.pay-methods-icons {
		display: none;

		@include media-breakpoint-down(md) {
			display: flex;
			justify-content: center;
			gap: gutter(3);
			margin-top: auto;
			padding-top: gutter(2);
			border-top: 1px solid var(--conakry);

			.nuxt-icon:deep(svg) {
				width: auto;
			}
		}
	}
}
</style>
